.todoname {
    margin: 0 10px;
    padding: 10px;
    text-decoration: underline;
    text-underline-color: #7DDA58;
    font-size: 20px;
    color: #222222;
    cursor: pointer;
}

.cancelBtn {
    background-color: darkred;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    color: white;
    cursor: pointer;
    margin-right: 10px;
}

.saveBtn {
    background-color: #7DDA58;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    color: white;
    cursor: pointer;
    margin-right: 10px;
}

.deleteBtn {
    background-color: #ff4d4d;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    color: white;
    cursor: pointer;
    margin-right: 10px;
}

.checkBox {
    margin: 0;
    height: 50px;
    padding: 10px;
    vertical-align: middle;
}

ul li input[type="text"] {
    margin: 5px;
    padding: 10px;
    border-radius: 5px;
    border: 2px solid #BFBABA;
    width: 300px;
}

ul li input[type="text"]:hover {
    border: 2px solid #000;
}
