html, body {
    margin: 0;
    padding: 0;
}

.Appheader {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    margin: 0;
    padding: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    color: white;
}

.h1 {
    font-family: Arial, sans-serif;
    text-align: center;
}

.inputcontainer {
    margin-bottom: 20px;
}

.inputcontainer input {
    margin: 5px;
    padding: 15px;
    border-radius: 5px;
    border: 1px solid #ccc;
    width: 300px;
}

.inputcontainer button {
    padding: 10px 20px;
    border-radius: 10px;
    border: none;
    background-color: #61dafb;
    color: white;
    cursor: pointer;
    height: 50px;
    width: 100px;
}
