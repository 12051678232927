.app {
    font-family: Arial, sans-serif;
    margin: 0;
}

.todolist {
    background-color: #282c34;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    margin-top: -500px;
    color: white;
}

ul {
    list-style-type: none;
    padding: 0;
}

ul li {
    background-color: #e0e0e0;
    margin: 30px 0;
    width: 600px;
    padding: 10px;
    border-radius: 5px;
    border: 5px solid #e0e0e0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

ul li:hover {
    border: 5px solid #BFBABA;
}
